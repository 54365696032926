@import url(https://fonts.googleapis.com/css?family=Raleway);
* {
    font-family: "Raleway", sans-serif;
}

.container {
    position: absolute;
    max-width: 720px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.title {
    position: relative;
    background-color: #25BEC2;
    color: rgb(255, 255, 255);
    padding: 20px;
    text-align: center;
    font-size: 1.8em;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.roundedcorner {
    position: relative;
    border-radius: 25px;
    background: #25BEC2;
    padding: 20px;
    box-sizing: border-box;
}

.ruleBox {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 5px;
    padding: 15px;
    box-sizing: border-box;
    background-color: rgb(250, 250, 250);
    display: flex;
    flex-direction: column;
}

.question {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 5px;
    color: rgb(255, 255, 255);
}

.textRules {
    font-size: 0.9em;
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 5px;
    color: #000000;;
}

.dot {
    height: 12px;
    width: 12px;
    margin-right: 8px;
    margin-top: 10px;
    margin-bottom: 5px;
    background-color: #25BEC2;
    border-radius: 50%;
    display: inline-block;
}

.textResult {
    font-size: 0.9em;
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 5px;
    color: #25BEC2;;
}

.questionDetails {
    font-size: 0.8em;
    margin-bottom: 5px;
    color: rgb(255, 255, 255);
}

.questionBox {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 5px;
    padding: 15px;
    box-sizing: border-box;
    background-color: rgb(250, 250, 250);
    display: flex;
    flex-direction: row;
}

.resultBox {
    position: relative;
    margin-top: 5px;
    padding: 15px;
    box-sizing: border-box;
    background-color: rgb(250, 250, 250);
    display: flex;
    flex-direction: row;
}

.ruleBulletBox {
    position: relative;
    margin-top: 5px;
    margin-left: 12px;
    box-sizing: border-box;
    background-color: rgb(250, 250, 250);
    display: flex;
    flex-direction: row;
}

.questionContainer {
    position: relative;
    width: 100%;
    border-radius: 25px;
    background: #25BEC2;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.questionImg {
    border-radius: 8px;
    margin-right: 16px;
}

.answerBtn {
    position: relative;
    padding: 10px;
    background-color: rgb(250, 151, 37);
    display: inline-block;
    margin: 10px;
    outline: none;
    border: none;
    font-size: 1em;
    color: rgb(255, 255, 255);
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
}

.nextBtn {
    position: relative;
    width: 100%;
    padding: 10px;
    background-color: #B6B6B6;
    display: inline-block;
    margin: auto;
    outline: none;
    border: none;
    font-size: 1em;
    color: rgb(255, 255, 255);
    font-weight: bold;
    cursor: pointer;
}

.score-board {
    position: relative;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.score {
    position: absolute;
    width: 1024px;
    font-size: 3em;
    top: 250px;
    text-align: center;
}

.playBtn {
    position: absolute;
    font-size: 1.4em;
    padding: 10px;
    background-color: #25BEC2;
    color: rgb(255, 255, 255);
    width: 150px;
    left: 0px;
    right: 0px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    outline: none;
    cursor: pointer;
}


